import { IterableListItem } from "@/components/Triggers/types";
import { PostActionStructure } from "../ActionTypes/PostActionStructure";
import { PostActionBuilder } from "../ActionTypes/PostActionModel";

import { IterableItemFactory } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import { PostMessage } from "piramis-base-components/src/shared/modules/posting/types";

import { instanceToPlain } from "class-transformer";
import { cloneDeep } from "lodash";
import arrayMove from "array-move";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";

@Component({})
export default class SelectPostActionMixin<T extends PostActionStructure> extends Vue {
    @Prop() postActions!: Array<any>

    @Prop() actionTypes!: Array<PostActionBuilder>

    @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

    @Prop() postMessage!: PostMessage

    @Prop({ type:Boolean, default: false }) validateActions!:boolean

    currentAction: IterableListItem<T> | null = null

    localActions: Array<IterableListItem<T>> | null = null

    iterableItemFactory = new IterableItemFactory()

    rawActionsToInstance(actions: Array<any>): Array<T> {
        throw new Error('Method must be override in component')
    }

    @Watch('postActions', { immediate: true })
    onChange(actions: Array<any>) {
        this.localActions = this.rawActionsToInstance(actions).map(this.iterableItemFactory.create)
    }

    @Emit()
    updateActions(a) {
        return a
    }

    rawLocalActions() {
        if (this.localActions) {
            return instanceToPlain(this.localActions.map(a => a.value))
        }
    }

    resetState() {
        this.currentAction = null
    }

    updateAction(item: T) {
        if (this.localActions) {
            if (this.currentAction) {
                const existedAction = this.localActions.find(a => a.guid === this.currentAction!.guid)

                if (existedAction) {
                    existedAction.value = cloneDeep(item)

                    this.updateActions(this.rawLocalActions())
                }
            } else {
                this.localActions.push(this.iterableItemFactory.create(item))

                this.updateActions(this.rawLocalActions())
            }
        }
    }

    removeAction(guid: string) {
        if (this.localActions) {
            this.localActions = this.localActions.filter(a => a.guid !== guid)

            this.updateActions(this.rawLocalActions())
        }
    }

    moveAction(direction: 'moveUp' | 'moveDown', currentIndex: number) {
        if (this.localActions) {
            const toDirection = direction === "moveUp" ? currentIndex + 1 : currentIndex - 1

            this.localActions = [ ...arrayMove(this.localActions, currentIndex, toDirection) ]

            this.updateActions(this.rawLocalActions())
        }
    }
}