import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { PostActionType } from "@/includes/types/Post.types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";
import { Exclude } from "class-transformer";
import GetUserInfoServiceImpl from "@/components/Post/NewActions/GetUserInfoService";

export default class NotifyUserActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.NotifyUserAction;

    message = ''

    users: Array<number> = []

    icon: string = 'notifications'

    view: VueComponent | undefined = () => import('./NotifyUserActionView.vue')
    valueView: VueComponent | undefined = () => import('./NotifyUserActionValue.vue')

    @Exclude()
    usersInfoService = new GetUserInfoServiceImpl()

    isDeprecated = true
    isVisible = false
}