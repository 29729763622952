













































































import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { timezoneList } from 'piramis-base-components/src/logic/timizone-list'
import { Schedule } from 'piramis-base-components/src/shared/modules/posting/types'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import moment from 'moment'
import * as Sentry from "@sentry/browser";

@Component({
  data() {
    return {
      timezoneList,
      moment,
    }
  }
})
export default class PostTime extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() scheduleModel!: Schedule

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  currentTimezone = 'UTC'

  timezoneModalOpen = false

  get postDate():string {
    return this.scheduleModel.run_time.split(' ')[0] ?? ''
  }

  set postDate(newDate:string) {
    this.updateDatetime(newDate, undefined)
  }

  get postTime():string {
    return this.scheduleModel.run_time.split(' ')[1] ?? ''
  }

  set postTime(newTime:string) {
    this.updateDatetime(undefined, newTime)
  }

  handleCancel() {
    this.currentTimezone = this.scheduleModel.timezone
  }

  updateDatetime(date: string | null = this.postDate, time = this.postTime):void {
    if (date === null) {
      Sentry.captureException(new Error('Date is null'), {
        extra: {
          ...this.scheduleModel
        }
      });
    }

    this.scheduleModel.run_time = `${ date === null ? moment().add('1', 'd').format('YYYY-MM-DD') : date } ${ time }`
  }

  handleTimezoneOkClick(): void {
    this.scheduleModel.timezone = this.currentTimezone
    this.timezoneModalOpen = false
  }

  initPostDate() {
    const postDate = this.$route.query?.date?.toString()

    if (postDate) {
      this.postDate = postDate
    } else {
      this.postDate = moment().add('1', 'd').format('YYYY-MM-DD')
    }
  }

  initPostTime() {
    const postTime = this.$route.query?.time?.toString()

    if (postTime) {
      this.postTime = postTime
    } else {
      this.postTime = moment().add('1', 'h').startOf('h').format('HH:mm:ss')
    }
  }

  mounted(): void {
    this.currentTimezone = this.scheduleModel.timezone

    if (this.scheduleModel.run_time) {
      const splitRunTime = this.scheduleModel.run_time.split(' ')

      this.postDate = splitRunTime[0]
      this.postTime = splitRunTime[1]
    } else {
      this.initPostTime()
      this.initPostDate()
    }
  }
}
